<template>
    <span>
        <template v-if="groups && groups.length">
            <slot>
                <span class="count-label">{{ groups.length }}</span>
            </slot>
            <Tooltip
                icon="info"
                class="text-gray-500 ml-2"
            >
                <ul class="groups-list">
                    <li
                        v-for="({ id, name }, index) in groups"
                        :key="index"
                    >
                        <router-link
                            v-if="level === 'ENTERPRISE' && hasPermission('EL_AGV')"
                            class="assignment-link underline"
                            :to="{
                                name: 'amenities.enterprise.groups.edit',
                                params: { groupId: id },
                            }"
                        >
                            {{ name }}
                        </router-link>
                        <router-link
                            v-else-if="level === 'COMMUNITY' && hasPermission('CL_AGV')"
                            class="assignment-link underline"
                            :to="{
                                name: 'amenities.community.groups.edit',
                                params: { groupId: id },
                            }"
                        >
                            {{ name }}
                        </router-link>
                        <span
                            v-else
                            class="text-3xs"
                        >{{ name }}</span>
                    </li>
                </ul>
            </Tooltip>
        </template>
        <template v-else>-</template>
    </span>
</template>

<script>
import Tooltip from '@/components/ui/Tooltip';
import { mapGetters } from 'vuex';

export default {
    components: { Tooltip },

    props: {
        groups: {
            type: Array,
            required: true,
        },

        level: {
            type: String,
            required: true,
        },
    },

    computed: {
        ...mapGetters({
            hasPermission: 'amnt/hasPermission',
        }),
    },
};
</script>

<style scoped>
.count-label {
    @apply inline-block;
    min-width: 1.5rem;
}

.groups-list {
    @apply font-frank leading-5;
    color: #4f98ca;
}

.groups-list li:before {
    content: '-';
    @apply mr-1;
}
</style>

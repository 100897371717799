<template>
    <div
        ref="searchBox"
        class="relative h-full"
    >
        <label class="relative block">
            <text-input
                v-model="query"
                placeholder="search by unit or/and building"
                @focus="handleFocus"
            />
        </label>
        <div
            v-if="opened && !loading"
            class="search-dropdown"
        >
            <div
                v-if="foundItems.length === 0"
                class="px-4 py-2 text-xs text-gray-500"
            >
                no results
            </div>

            <div
                v-else
                class="search-group"
            >
                <div class="flex items-center h-8 px-4 text-gray-500">
                    units
                </div>
                <SearchItem
                    v-for="item in foundItems"
                    :key="item.id"
                    :item="item"
                    :search-query="query"
                    :selected="isSelected(item)"
                    :with-details="true"
                    @click="clickTag"
                />
            </div>
        </div>

        <div class="mt-4">
            <InfoPanel
                v-if="value.length === 0"
                text="you have not selected any units yet"
            />
            <Tag
                v-for="item in value"
                :key="item.id"
                :text="`Unit ${item.name}, building ${item.buildingName}`"
                :deletable="true"
                @click="clickTag(item)"
            />
        </div>
    </div>
</template>

<script>
import { debounce } from 'lodash-es';
import NotifyMixin from '@/mixins/NotifyMixin';
import SearchItem from '@/components/amenities/SearchItem';
import Tag from '@/components/ui/Tag';
import InfoPanel from '@/components/amenities/InfoPanel';
import TextInput from '@/components/ui/TextInput';

export default {
    components: { InfoPanel, Tag, SearchItem, TextInput },

    mixins: [NotifyMixin],

    props: {
        value: {
            type: Array,
            required: true,
        },
    },

    emits: ['input'],

    data() {
        return {
            loading: false,
            query: '',
            opened: false,
            foundItems: [],
        };
    },

    watch: {
        query: debounce(function () {
            this.searchTags();
        }, 500),

        opened(value) {
            if (!value) {
                return;
            }

            this.searchTags();
        },
    },

    mounted() {
        window.addEventListener('mousedown', this.handleMouseDown);
    },

    beforeUnmount() {
        window.removeEventListener('mousedown', this.handleMouseDown);
    },

    methods: {
        searchTags() {
            this.loading = true;
            this.$amntDataProvider
                .search('locations', { q: this.query })
                .then(res => {
                    this.foundItems = res;
                })
                .catch(error => this.notifyError(error.message))
                .finally(() => {
                    this.loading = false;
                });
        },

        isSelected(tag) {
            return !!this.value.find(loc => loc.id === tag.id);
        },

        clickTag(tag) {
            this.query = '';
            this.opened = false;

            this.$emit('input', this.isSelected(tag) ? this.value.filter(loc => loc.id !== tag.id) : [...this.value, tag]);
        },

        handleFocus() {
            this.opened = true;
        },

        handleMouseDown(event) {
            if (!this.$refs.searchBox.contains(event.target) || this.$refs.searchBox === event.target) {
                this.opened = false;
            }
        },
    },
};
</script>

<style scoped>
.search-dropdown {
    @apply absolute w-full border border-t-0 bg-white overflow-x-hidden overflow-y-auto;
    max-height: calc(100% - 4.25rem);
    top: auto;
}

.search-group {
    @apply py-2 text-xs;
}

.search-group + .search-group {
    @apply border-t;
}
</style>

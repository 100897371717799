<template>
    <FinalForm
        ref="form"
        :submit="onSubmit"
        :initial-values="initialValues"
        :validate="validate"
        class="h-full"
    >
        <template #default="{ handleSubmit, values, errors, touched }">
            <form
                class="form-narrow flex flex-col justify-between min-h-full h-full"
                @submit="handleSubmit"
            >
                <div class="flex-grow overflow-y-auto">
                    <div class="grid grid-cols-12 mb-1">
                        <div class="form-col col-span-5">
                            <TextField
                                name="name"
                                :label="`amenity group name${mandatoryLabel}`"
                                :validate="composeValidators(required, minLength(2), maxLength(150))"
                                autocomplete="off"
                                :edit-mode="editMode"
                            />
                        </div>
                        <div class="form-col col-span-7">
                            <TextField
                                name="description"
                                :label="`description${mandatoryLabel}`"
                                :validate="composeValidators(required, minLength(5), maxLength(5000))"
                                placeholder="Provide an explanation to accurately understand this group"
                                rows="1"
                                multiline
                                :edit-mode="editMode"
                            />
                        </div>
                    </div>
                    <div class="form-row mt-8">
                        <div class="form-col">
                            <div class="flex items-baseline justify-between">
                                <label class="w-2/3">individual amenities{{ mandatoryLabel }}</label>
                                <button
                                    v-if="editMode"
                                    class="btn-primary btn-transparent"
                                    type="button"
                                    :disabled="!isNewRowBtnVisible(values)"
                                    @click.stop="addNewRow"
                                >
                                    <icon
                                        class="w-4 h-4 mr-1"
                                        name="plus"
                                    />
                                    add new
                                </button>
                            </div>
                            <list
                                :columns="columns"
                                :items="items"
                                track-by="amenityId"
                                :css="listCss"
                                resource-label="amenities"
                            >
                                <template #column:name="{ item, index }">
                                    <AmenityField
                                        :key="index"
                                        :row-data="item"
                                        :row-index="index"
                                        :row-field="{ getAvailableAmenities }"
                                        :edit-mode="editMode"
                                    />
                                </template>

                                <template #column:pricingUnit="{ value }">
                                    {{ getPricingUnitLabel(value) || '-' }}
                                </template>

                                <template #column:amount="{ item, index }">
                                    <AmenityAmountField
                                        :key="index"
                                        :row-data="item"
                                        :row-index="index"
                                        :edit-mode="editMode"
                                    />
                                </template>

                                <template #column:price="{ item, index }">
                                    <AmenityPriceField
                                        :key="index"
                                        :row-data="item"
                                        :row-index="index"
                                    />
                                </template>

                                <template #column:inline-actions="{ index }">
                                    <button
                                        v-if="editMode"
                                        class="btn-action-warning"
                                        type="button"
                                        @click.stop="removeRow(index)"
                                    >
                                        <icon
                                            class="w-3 h-3"
                                            name="close2"
                                        />
                                    </button>
                                </template>
                            </list>
                            <span
                                v-if="
                                    errors.generalGroupAmenities &&
                                        (touched['groupAmenities[0].amenityId'] || !touched.hasOwnProperty('groupAmenities[0].amenityId'))
                                "
                                class="form-hint form-error"
                            >
                                {{ errors.generalGroupAmenities }}
                            </span>
                        </div>
                    </div>
                </div>
                <TotalPanel
                    :edit-mode="editMode"
                    :is-group="true"
                    :values="values"
                />
                <modal-footer
                    v-if="editMode"
                    :footer="footer"
                    :tertiary="close"
                />
            </form>
        </template>
    </FinalForm>
</template>

<script>
import { mapGetters } from 'vuex';
import { FinalForm } from 'vue-final-form';
import { round } from '@/utils/Numbers';
import ModalFooter from '@/components/ui/modals/ModalFooter';
import Icon from '@/components/ui/Icon';
import ModalNavigation from '@/mixins/ModalNavigation';
import AmenityMixin from '@/mixins/AmenityMixin';
import ValidatorMixin from '@/components/form/ValidatorMixin';
import ActionsMixin from '@/mixins/ActionsMixin';
import InitializeFormMixin from '@/components/form/InitializeFormMixin';
import TextField from '@/components/form/TextField';
import AmenityField from '@/components/amenities/list/fields/AmenityField';
import AmenityAmountField from '@/components/amenities/list/fields/AmenityAmountField';
import AmenityPriceField from '@/components/amenities/list/fields/AmenityPriceField';
import { initialValues } from '@/views/amenities/groups/constants';
import TotalPanel from '@/components/amenities/TotalPanel';
import List from '@/components/list/List';
import { DISCOUNT_TYPES } from '@/components/amenities/constants';

export default {
    name: 'GroupFormEL',

    components: {
        ModalFooter,
        FinalForm,
        TextField,
        Icon,
        TotalPanel,
        List,
        AmenityField,
        AmenityAmountField,
        AmenityPriceField,
    },

    mixins: [ModalNavigation, ValidatorMixin, AmenityMixin, InitializeFormMixin, ActionsMixin],

    props: {
        editMode: {
            type: Boolean,
            required: true,
        },

        amenities: {
            type: Array,
            required: true,
        },

        onSubmit: {
            type: Function,
            required: true,
        },

        initialValues: {
            type: Object,
            default: () => ({}),
        },

        validate: {
            type: Function,
            required: false,
            default: null,
        },

        error: {
            type: Object,
            required: false,
            default: null,
        },
    },

    data() {
        return {
            items: [],
        };
    },

    computed: {
        ...mapGetters({
            hasPermission: 'amnt/hasPermission',
        }),

        columns() {
            if (this.editMode) {
                return [
                    {
                        name: 'name',
                        title: 'name',
                        class: 'w-5/12',
                    },
                    {
                        name: 'pricingUnit',
                        title: 'per',
                        class: 'w-1/12',
                    },
                    {
                        name: 'amount',
                        title: 'qty',
                        class: 'w-3/12',
                    },
                    {
                        name: 'price',
                        title: 'price w/o discount',
                        class: 'w-2/12 text-right',
                    },
                    {
                        name: 'inline-actions',
                        title: '',
                        class: 'w-1/12',
                    },
                ];
            }

            return [
                {
                    name: 'name',
                    title: 'name',
                    class: 'w-5/12',
                },
                {
                    name: 'pricingUnit',
                    title: 'per',
                    class: 'w-1/12',
                },
                {
                    name: 'amount',
                    title: 'qty',
                    class: 'w-3/12',
                },
                {
                    name: 'price',
                    title: 'price w/o discount',
                    class: 'w-3/12 text-right',
                },
            ];
        },

        footer() {
            return {
                primaryButton: 'save',
                tertiaryButton: 'cancel',
            };
        },

        groupId() {
            return this.$route.params.groupId;
        },

        formState() {
            return this.$refs.form.formState;
        },

        formValues() {
            return this.formState.values;
        },

        finalForm() {
            return this.$refs.form?.finalForm;
        },

        initialAmenity() {
            return initialValues.groupAmenities[0];
        },

        mandatoryLabel() {
            return this.editMode ? '*' : '';
        },

        listCss() {
            return this.editMode ? { tableClass: 'table table--highlighted' } : { tableClass: 'table table--striped', bodyRowClass: 'row' };
        },
    },

    mounted() {
        this.initializeWatchers();

        this.formUnsubscribe = this.finalForm.subscribe(
            formState => {
                this.items = formState.values.groupAmenities;
            },
            { values: true }
        );
    },

    beforeUnmount() {
        this.formUnsubscribe();
    },

    methods: {
        removeRow(index) {
            this.finalForm.change('groupAmenities', [
                ...this.formValues.groupAmenities.slice(0, index),
                ...this.formValues.groupAmenities.slice(index + 1),
            ]);
        },

        addNewRow() {
            this.finalForm.change('groupAmenities', [
                {
                    ...this.initialAmenity,
                },
                ...this.formValues.groupAmenities,
            ]);
        },

        isNewRowBtnVisible(values) {
            return this.amenities.length > values?.groupAmenities.length;
        },

        getAvailableAmenities(selectedAmenity) {
            const selectedAmenities = this.formValues.groupAmenities.map(({ amenityId }) => amenityId);
            const selectedExceptCurrent = selectedAmenities.filter(amenityId => amenityId !== selectedAmenity);
            const availableAmenities = this.amenities.filter(({ value: { id } }) => !selectedExceptCurrent.includes(id));

            return availableAmenities;
        },

        getPrice(groupAmenities) {
            const priceReducer = (acc, { price, amount, pricingUnit }) => {
                const numberAmount = pricingUnit ? amount : 1;

                return acc + price * numberAmount;
            };

            return round(groupAmenities.reduce(priceReducer, 0), this.precision);
        },

        getChangedIndex(groupAmenities, oldGroupAmenities, field) {
            return groupAmenities.findIndex((amenity, i) => oldGroupAmenities[i][field] !== amenity[field]);
        },

        initializeWatchers() {
            if (this.groupId) {
                this.$watch(
                    () => [this.formState.valid, this.formState.dirtySinceLastSubmit, this.formState.dirty],
                    ([valid, dirtySinceLastSubmit, dirty]) => {
                        const actionId = 'assign-community';
                        const { submitSucceeded } = this.formState;
                        const notSaved = this.error || (!submitSucceeded && dirty) || (submitSucceeded && dirtySinceLastSubmit);

                        if (this.hasConfirmAction(actionId)) {
                            this.removeConfirmAction(actionId);
                        } else {
                            this.removeActionFromStore({
                                title: 'assign communities',
                                routeName: this.$route.name,
                            });
                        }

                        if (valid && this.hasPermission('EL_CA')) {
                            if (notSaved) {
                                this.addConfirmAction(
                                    {
                                        id: actionId,
                                        title: 'assign communities',
                                        showConfirmation: true,
                                        confirmationMessage:
                                            'you have not saved the latest changes. Save and assign communities or go back?',
                                        confirmBtnText: 'save and assign communities',
                                        cancelBtnText: 'go back',
                                        values: this.formValues,
                                    },
                                    () => {}
                                );
                            } else {
                                this.addActionToStore({
                                    routeName: this.$route.name,
                                    item: {
                                        id: actionId,
                                        title: 'assign communities',
                                        routeName: 'amenities.communities-assignment',
                                        params: { id: '{groupId}' },
                                    },
                                });
                            }
                        }
                    }
                );

                const unwatch = this.$watch('initialValues', function () {
                    this.initializeDiscountTypeWatcher();
                    if (unwatch) {
                        unwatch();
                    }
                });
            } else {
                this.initializeDiscountTypeWatcher();
            }

            this.$watch(
                () => this.formValues.groupAmenities,
                (groupAmenities, oldGroupAmenities) => {
                    if (groupAmenities.length === oldGroupAmenities.length) {
                        // check if amenityId was changed
                        const changedIdIndex = this.getChangedIndex(groupAmenities, oldGroupAmenities, 'amenityId');

                        if (changedIdIndex >= 0 && this.amenities.length) {
                            const changedAmenityId = groupAmenities[changedIdIndex].amenityId;
                            const { pricingUnit, amount, price } = this.initialAmenity;
                            let updatedAmenity = {
                                ...this.formValues.groupAmenities[changedIdIndex],
                                pricingUnit,
                                amount,
                                price,
                            };

                            if (changedAmenityId) {
                                const {
                                    value: { pricingUnit, price, name },
                                } = this.amenities.find(({ value: { id } }) => id === changedAmenityId);

                                updatedAmenity = {
                                    ...updatedAmenity,
                                    pricingUnit,
                                    price,
                                    name,
                                };

                                if (!pricingUnit) {
                                    updatedAmenity.amount = null;
                                }
                            }

                            const newGroupAmenities = [
                                ...this.formValues.groupAmenities.slice(0, changedIdIndex),
                                { ...updatedAmenity },
                                ...this.formValues.groupAmenities.slice(changedIdIndex + 1),
                            ];

                            this.finalForm.change('groupAmenities', newGroupAmenities);
                        }

                        // check if amount was changed
                        const changedAmountIndex = this.getChangedIndex(groupAmenities, oldGroupAmenities, 'amount');
                        // check if price was changed
                        const changedPriceIndex = this.getChangedIndex(groupAmenities, oldGroupAmenities, 'price');

                        if (changedPriceIndex >= 0 || changedAmountIndex >= 0) {
                            this.finalForm.change('price', this.getPrice(groupAmenities));
                        }
                    } else if (groupAmenities.length < oldGroupAmenities.length) {
                        this.finalForm.change('price', this.getPrice(groupAmenities));
                    }
                }
            );

            this.$watch(
                () => [this.formValues.price, this.formValues.discount],
                ([price, discount]) => {
                    this.finalForm.change('total', this.getTotal(price, { discount, type: this.formValues.discountType }));
                }
            );

            this.$watch(
                () => this.formValues.price,
                price => {
                    const { discount, discountType } = this.formValues;

                    if (price < 0) {
                        this.finalForm.change('discount', 0);
                    } else if (price < discount && discountType === DISCOUNT_TYPES.SIMPLE) {
                        this.$refs.form.finalForm.change('discount', price);
                    }
                }
            );
        },

        initializeDiscountTypeWatcher() {
            this.$watch(
                () => this.formValues.discountType,
                () => this.finalForm.change('discount', 0)
            );
        },
    },
};
</script>

<template>
    <div>
        <div class="font-frank font-bold text-sm text-black mb-4">
            “{{ name }}” individual amenity was assigned to {{ getGroupsLabel(groups.length) }}
        </div>
        <List
            :columns="columns"
            :items="groups"
            @rowClick="handleRowClick"
        >
            <template #column:name="{ item }">
                <span
                    :class="
                        (communityLevel && hasPermission('CL_AGV')) || (!communityLevel && hasPermission('EL_AGV'))
                            ? 'assignment-link underline'
                            : 'text-sm'
                    "
                >
                    {{ item.name }}
                </span>
            </template>
        </List>
        <Loader :loading="loading" />
    </div>
</template>

<script>
import ModalNavigation from '@/mixins/ModalNavigation';
import NotifyMixin from '@/mixins/NotifyMixin';
import AmenityMixin from '@/mixins/AmenityMixin';
import List from '@/components/list/List';
import Loader from '@/components/ui/Loader';
import { mapGetters } from 'vuex';

export default {
    name: 'GroupMembership',

    components: {
        List,
        Loader,
    },

    mixins: [ModalNavigation, NotifyMixin, AmenityMixin],

    data() {
        return {
            name: '',
            groups: [],
            columns: [
                {
                    name: 'name',
                    title: 'groups',
                },
            ],

            loading: true,
        };
    },

    computed: {
        ...mapGetters({
            hasPermission: 'amnt/hasPermission',
        }),

        id() {
            return this.$route.params.amenityId;
        },

        communityLevel() {
            return this.$route.name === 'amenities.community.individual.groupMembership';
        },
    },

    async mounted() {
        try {
            if (this.communityLevel) {
                const { amenity, communityGroups } = await this.$amntDataProvider.getList('communityAmenityMembership', { id: this.id });
                this.name = amenity.name;
                this.groups = communityGroups.map(({ id, group }) => ({ id, name: group.name }));
            } else {
                const { name, groups } = await this.$amntDataProvider.getList('amenityMembership', { id: this.id });
                this.name = name;
                this.groups = groups;
            }
        } catch (error) {
            this.notifyError(error.message);
        } finally {
            this.loading = false;
        }
    },

    methods: {
        handleRowClick({ id }) {
            if (this.communityLevel && this.hasPermission('CL_AGV')) {
                this.$router.push({
                    name: 'amenities.community.groups.edit',
                    params: { groupId: id },
                });
            }

            if (!this.communityLevel && this.hasPermission('EL_AGV')) {
                this.$router.push({
                    name: 'amenities.enterprise.groups.edit',
                    params: { groupId: id },
                });
            }
        },
    },
};
</script>

<template>
  <div class="flex flex-col flex-grow overflow-auto">
    <div
      v-for="item of items"
      :key="item[trackBy]"
      class="flex items-center justify-between border p-3 mb-2 cursor-pointer"
      :class="{'border-blue-500': item[trackBy] === value}"
      @click="$emit('input', item[trackBy])"
    >
      <div
        class="text-2sm font-normal font-frank lowercase"
        :class="item[trackBy] === value ? 'text-blue-500' : 'text-black'"
      >
        {{item[label]}}
      </div>
      <div v-if="counter" class="text-xs font-medium text-gray-500 bg-gray-200 py-1 px-2 rounded-full">
        {{item[counter]}}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    /**
     * Array of available items
     */
    items: {
      type: Array,
      required: true,
    },

    /**
     * The selected item value
     */
    value: {
      type: null,
      required: false,
    },

    /**
     * Key to find an item
     */
    trackBy: {
      type: String,
      default: 'id',
    },

    /**
     * Label to look for in item object
     */
    label: {
      type: String,
      default: 'name',
    },

    /**
     * Counter to look for in item object
     */
    counter: {
      type: String,
      required: false,
      default: null
    },
  },

  emits: [
    'input',
  ],
};
</script>

<template>
    <SelectInput
        class="select"
        :name="`groupAmenities[${rowIndex}].amenityId`"
        placeholder="Select amenity"
        :options="options"
        :value="rowData.amenityId"
        :validate="required"
        :edit-mode="editMode"
    />
</template>

<script>
import SelectInput from '@/components/form/SelectInput';
import ValidatorMixin from '@/components/form/ValidatorMixin';

export default {
    name: 'AmenityField',

    components: {
        SelectInput,
    },

    mixins: [ValidatorMixin],

    props: {
        editMode: {
            type: Boolean,
            default: true,
        },

        rowField: {
            type: Object,
            required: true,
        },

        rowData: {
            type: Object,
            required: true,
        },

        rowIndex: {
            type: Number,
            required: true,
        },
    },

    computed: {
        amenities() {
            return this.rowField.getAvailableAmenities(this.rowData.amenityId);
        },

        options() {
            return this.amenities.map(({ value: { id, name } }) => ({
                key: id,
                value: name,
            }));
        },
    },
};
</script>

<style scoped>
.select:deep(.multiselect__single),
.select:deep(.multiselect__option),
.select:deep(.multiselect__placeholder),
.select:deep(.multiselect__input) {
    font-size: 0.8125rem;
}
</style>

import { DISCOUNT_TYPES } from '@/components/amenities/constants';

export const initialValues = {
    name: '',
    description: '',
    groupAmenities: [
        {
            amenityId: null,
            name: '',
            pricingUnit: null,
            amount: 1,
            price: 0.0,
        },
    ],
    price: 0.0,
    discount: 0,
    discountType: DISCOUNT_TYPES.PERCENT,
    total: 0.0,
};

<template>
    <span>
        <span v-if="individual">as individual amenity</span>
        <span v-if="firstGroup">
            as part of&#32;
            <router-link
                v-if="level === 'ENTERPRISE' && hasPermission('EL_AGV')"
                class="assignment-link underline"
                :to="{
                    name: 'amenities.enterprise.groups.edit',
                    params: { groupId: firstGroup.id },
                }"
            >{{ firstGroup.name }}</router-link>
            <router-link
                v-else-if="level === 'COMMUNITY' && hasPermission('CL_AGV')"
                class="assignment-link underline"
                :to="{
                    name: 'amenities.community.groups.edit',
                    params: { groupId: firstGroup.id },
                }"
            >{{ firstGroup.name }}</router-link>
            <span v-else>{{ firstGroup.name }}</span>
            &#32;group
        </span>
        <span v-if="otherGroups.length"><AssignedGroups
            :groups="otherGroups"
            :level="level"
        >{{ assignedGroupsLabel }}</AssignedGroups></span>
    </span>
</template>

<script>
import AmenityMixin from '@/mixins/AmenityMixin';
import AssignedGroups from '@/components/amenities/AssignedGroups';
import { mapGetters } from 'vuex';

export default {
    components: { AssignedGroups },
    mixins: [AmenityMixin],

    props: {
        location: {
            type: Object,
            required: true,
        },

        level: {
            type: String,
            required: true,
        },
    },

    computed: {
        ...mapGetters({
            hasPermission: 'amnt/hasPermission',
        }),

        individual() {
            return this.location.withIndividualLink;
        },

        allGroups() {
            return (
                this.location.communityGroups?.map(cg => ({
                    id: cg.id,
                    name: cg.group.name,
                })) ||
                this.location.groups ||
                []
            );
        },

        firstGroup() {
            return this.individual || this.allGroups.length === 0 ? null : this.allGroups[0];
        },

        otherGroups() {
            return this.individual ? this.allGroups : this.allGroups.slice(1);
        },

        assignedGroupsLabel() {
            const groupsCount = this.otherGroups.length;
            return this.individual
                ? ` and ${this.getGroupsLabel(groupsCount)}`
                : ` +${groupsCount} more ${this.$i18n.tc('app.group', groupsCount)}`;
        },
    },
};
</script>

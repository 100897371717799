<template>
    <div class="h-full">
        <FinalForm
            ref="form"
            :submit="handleSubmit"
            :initial-values="initialValues"
            class="h-full"
        >
            <template #default="{ handleSubmit, values }">
                <form
                    class="form-narrow flex flex-col justify-between min-h-full h-full"
                    @submit="handleSubmit"
                >
                    <div class="flex flex-grow overflow-y-auto">
                        <div class="form-col w-2/5">
                            <div class="form-row">
                                <TextField
                                    name="name"
                                    :label="`amenity name${mandatoryLabel}`"
                                    :validate="composeValidators(required, minLength(2), maxLength(150))"
                                    autocomplete="off"
                                    :edit-mode="editMode"
                                />
                            </div>
                            <div class="form-row">
                                <div class="mr-10">
                                    <RadioGroupInput
                                        name="quantityBasedPricing"
                                        :label="`quantity-based pricing?${mandatoryLabel}`"
                                        :options="quantityBasedPricingOptions"
                                        :edit-mode="editMode && !amenityId"
                                    />
                                    <div
                                        v-if="editMode && !amenityId"
                                        class="pricing-warning flex font-inter ml-1"
                                    >
                                        <span><icon
                                            class="w-4 h-4 mr-2"
                                            name="Info"
                                        /></span>
                                        <span class="pricing-warning--text">after saving you will not be able to edit it</span>
                                    </div>
                                </div>
                                <RadioGroupInput
                                    v-if="values.quantityBasedPricing === '1'"
                                    name="pricingUnit"
                                    :label="`pricing variable?${mandatoryLabel}`"
                                    :options="pricingUnitsOptions"
                                    :edit-mode="editMode"
                                />
                            </div>
                        </div>
                        <div class="form-col flex-grow">
                            <div class="form-row">
                                <TextField
                                    name="description"
                                    :label="`description${mandatoryLabel}`"
                                    :validate="composeValidators(required, minLength(5), maxLength(5000))"
                                    placeholder="Provide an explanation to accurately understand this amenity"
                                    rows="6"
                                    multiline
                                    :edit-mode="editMode"
                                />
                            </div>
                        </div>
                    </div>
                    <TotalPanel
                        :edit-mode="editMode"
                        :is-group="false"
                        :values="values"
                    />
                    <modal-footer
                        v-if="editMode"
                        :footer="footer"
                        :tertiary="close"
                    />
                </form>
            </template>
        </FinalForm>
        <loader
            :loading="loading"
            :backdrop="true"
        />
    </div>
</template>

<script>
import { FinalForm } from 'vue-final-form';
import NotifyMixin from '@/mixins/NotifyMixin';
import ModalNavigation from '@/mixins/ModalNavigation';
import ActionsMixin from '@/mixins/ActionsMixin';
import AmenityMixin from '@/mixins/AmenityMixin';
import Loader from '@/components/ui/Loader';
import ModalFooter from '@/components/ui/modals/ModalFooter';
import Icon from '@/components/ui/Icon';
import ValidatorMixin from '@/components/form/ValidatorMixin';
import InitializeFormMixin from '@/components/form/InitializeFormMixin';
import TextField from '@/components/form/TextField';
import RadioGroupInput from '@/components/form/RadioGroupInput';
import TotalPanel from '@/components/amenities/TotalPanel';
import { mapGetters } from 'vuex';
import { DISCOUNT_TYPES } from '@/components/amenities/constants';

export default {
    components: {
        Loader,
        TotalPanel,
        ModalFooter,
        TextField,
        RadioGroupInput,
        FinalForm,
        Icon,
    },

    mixins: [ModalNavigation, NotifyMixin, ActionsMixin, ValidatorMixin, InitializeFormMixin, AmenityMixin],

    data() {
        return {
            initialValues: {
                name: '',
                description: '',
                quantityBasedPricing: '0',
                pricingUnit: null,
                percentDiscount: true,
                discount: 0,
                discountType: DISCOUNT_TYPES.PERCENT,
                price: 0.0,
            },

            loading: true,
            error: null,
            quantityBasedPricingOptions: [
                { key: '1', value: 'yes' },
                { key: '0', value: 'no' },
            ],

            pricingUnitsOptions: [],
        };
    },

    computed: {
        ...mapGetters({
            hasPermission: 'amnt/hasPermission',
        }),

        editMode() {
            return this.hasPermission('EL_AM');
        },

        footer() {
            return {
                primaryButton: 'save',
                tertiaryButton: 'cancel',
            };
        },

        amenityId() {
            return this.$route.params.amenityId;
        },

        formState() {
            return this.$refs.form.formState;
        },

        formValues() {
            return this.formState.values;
        },

        mandatoryLabel() {
            return this.editMode ? '*' : '';
        },
    },

    mounted() {
        this.initializeWatchers();

        Promise.all([this.amenityId && this.fetchAmenity(), this.fetchPricingUnits()])
            .then(() => {
                this.$watch(
                    () => this.formValues.discountType,
                    () => this.$refs.form.finalForm.change('discount', 0)
                );
            })
            .catch(error => {
                this.close();
                this.notifyError(error.message);
            })
            .finally(() => {
                this.loading = false;
            });

        const actionId = 'amnt-delete-amenity';

        if (this.hasConfirmAction(actionId)) {
            this.removeConfirmAction(actionId);
        } else {
            this.removeActionFromStore({
                title: 'delete amenity',
                routeName: this.$route.name,
            });
        }

        if (this.amenityId && this.editMode) {
            this.addConfirmAction(
                {
                    id: actionId,
                    title: 'delete amenity',
                    showConfirmation: true,
                    confirmationMessage: 'are you sure you want to delete this amenity?',
                    confirmBtnText: 'yes, delete',
                    cancelBtnText: 'no, go back',
                    confirmationType: 'warning',
                },
                this.deleteAmenity
            );
        }
    },

    methods: {
        async submitForm(values = this.formValues) {
            const { name, description, discount, discountType, quantityBasedPricing, pricingUnit, price } = values;

            const data = {
                name,
                description,
                discount,
                discountType,
                pricingUnit,
                quantityBasedPricing: Boolean(Number(quantityBasedPricing)),
                price,
            };
            let result;

            if (this.amenityId) {
                result = await this.$amntDataProvider.update('amenities', { id: this.amenityId, data });
                this.notifySuccess('Amenity has been updated');
            } else {
                result = await this.$amntDataProvider.create('amenities', { data });
                this.notifySuccess('The amenity was successfully created');
            }

            return result;
        },

        async handleSubmit(values) {
            if (this.loading) {
                return;
            }

            this.loading = true;

            try {
                if (this.amenityId) {
                    const { name } = await this.submitForm(values);

                    this.setActiveModalName(name);
                } else {
                    await this.submitForm(values);
                    this.close();
                }
                this.error = null;
            } catch (error) {
                this.notifyError(error.message);
                this.error = error;
            } finally {
                this.loading = false;
            }
        },

        fetchAmenity() {
            return this.$amntDataProvider
                .getOne('amenities', { id: this.amenityId })
                .then(({ quantityBasedPricing, name, ...restValues }) => {
                    this.initialValues = {
                        quantityBasedPricing: Number(quantityBasedPricing).toString(),
                        name,
                        ...restValues,
                    };
                    this.setActiveModalName(name);
                });
        },

        fetchPricingUnits() {
            return this.$amntDataProvider.get('pricingUnits').then(pricingUnits => {
                this.pricingUnitsOptions = pricingUnits.map(({ code, description }) => ({ key: code, value: description }));
            });
        },

        deleteAmenity() {
            this.$amntDataProvider
                .delete('amenities', { id: this.amenityId })
                .then(() => {
                    this.close();
                    this.notifySuccess('Amenity deleted');
                })
                .catch(error => {
                    this.notifyError(error.message);
                });
        },

        initializeWatchers() {
            if (this.amenityId) {
                this.$watch(
                    () => [this.formState.valid, this.formState.dirtySinceLastSubmit, this.formState.dirty],
                    ([valid, dirtySinceLastSubmit, dirty]) => {
                        const actionId = 'assign-community';
                        const { submitSucceeded } = this.formState;
                        const notSaved = this.error || (!submitSucceeded && dirty) || (submitSucceeded && dirtySinceLastSubmit);

                        if (this.hasConfirmAction(actionId)) {
                            this.removeConfirmAction(actionId);
                        } else {
                            this.removeActionFromStore({
                                title: 'assign communities',
                                routeName: this.$route.name,
                            });
                        }

                        if (valid && this.hasPermission('EL_CA')) {
                            if (notSaved) {
                                this.addConfirmAction(
                                    {
                                        id: actionId,
                                        title: 'assign communities',
                                        showConfirmation: true,
                                        confirmationMessage:
                                            'you have not saved the latest changes. Save and assign communities or go back?',
                                        confirmBtnText: 'save and assign communities',
                                        cancelBtnText: 'go back',
                                    },
                                    () => {
                                        this.submitForm()
                                            .then(({ id }) => {
                                                this.$router.push({
                                                    name: 'amenities.communities-assignment',
                                                    params: { id },
                                                });
                                                this.error = null;
                                            })
                                            .catch(error => {
                                                this.notifyError(error.message);
                                                this.error = error;
                                            });
                                    }
                                );
                            } else {
                                this.addActionToStore({
                                    routeName: this.$route.name,
                                    item: {
                                        id: actionId,
                                        title: 'assign communities',
                                        routeName: 'amenities.communities-assignment',
                                        params: { id: '{amenityId}' },
                                    },
                                });
                            }
                        }
                    }
                );
            }

            if (!this.amenityId) {
                this.$watch(
                    () => this.formValues.quantityBasedPricing,
                    val => {
                        const pricingUnit = Number(val) && this.pricingUnitsOptions.length ? this.pricingUnitsOptions[0].key : null;

                        this.$refs.form.finalForm.change('pricingUnit', pricingUnit);
                    }
                );
            }

            this.$watch(
                () => this.formValues.price,
                price => {
                    const { discount, discountType } = this.formValues;

                    if (price < 0) {
                        this.$refs.form.finalForm.change('discount', 0);
                    } else if (price < discount && discountType === DISCOUNT_TYPES.SIMPLE) {
                        this.$refs.form.finalForm.change('discount', price);
                    }
                }
            );

            this.$watch(
                () => [this.formValues.price, this.formValues.discount],
                ([price, discount]) => {
                    this.$refs.form.finalForm.change('total', this.getTotal(price, { discount, type: this.formValues.discountType }));
                },
                {
                    immediate: true,
                }
            );
        },
    },
};
</script>

<style scoped>
.pricing-warning {
    color: black;
}

.pricing-warning--text {
    max-width: 115px;
    @apply font-frank text-xs;
}
</style>

<template>
    <FinalForm
        ref="form"
        :submit="onSubmit"
        :initial-values="initialValues"
        class="h-full"
    >
        <template #default="{ handleSubmit, values }">
            <form
                class="form-narrow flex flex-col justify-between min-h-full h-full"
                @submit="handleSubmit"
            >
                <div class="flex-grow overflow-y-auto">
                    <div class="grid grid-cols-12 gap-4 mb-1">
                        <div class="col-span-5">
                            <div class="form-row">
                                <TextField
                                    name="name"
                                    label="amenity group name"
                                    autocomplete="off"
                                    :edit-mode="false"
                                />
                            </div>
                            <div class="form-row">
                                <SelectInput
                                    name="chargeCodeId"
                                    label="charge code"
                                    :options="chargeCodesOptions"
                                    :edit-mode="editMode"
                                />
                            </div>
                        </div>
                        <div class="col-span-7">
                            <TextField
                                name="description"
                                label="description"
                                placeholder="Provide an explanation to accurately understand this group"
                                rows="1"
                                multiline
                                :edit-mode="false"
                            />
                        </div>
                    </div>
                    <div class="form-row mt-8">
                        <div class="form-col">
                            <div class="flex items-baseline justify-between">
                                <label class="w-2/3">individual amenities</label>
                            </div>
                            <list
                                :columns="columns"
                                :items="items"
                                track-by="amenityId"
                                :css="{ tableClass: 'table table--striped', bodyRowClass: 'row' }"
                                resource-label="amenities"
                            >
                                <template #column:pricingUnit="{ value }">
                                    {{ getPricingUnitLabel(value) || '-' }}
                                </template>

                                <template #column:amount="{ item, index }">
                                    <AmenityAmountField
                                        :key="index"
                                        :row-data="item"
                                        :row-index="index"
                                        :edit-mode="false"
                                    />
                                </template>

                                <template #column:price="{ item, index }">
                                    <AmenityPriceField
                                        :key="index"
                                        :row-data="item"
                                        :row-index="index"
                                    />
                                </template>
                            </list>
                        </div>
                    </div>
                </div>
                <TotalPanel
                    :edit-mode="editMode"
                    level="COMMUNITY"
                    :is-group="true"
                    :values="values"
                />
                <modal-footer
                    v-if="editMode"
                    :footer="footer"
                    :tertiary="close"
                />
            </form>
        </template>
    </FinalForm>
</template>

<script>
import { FinalForm } from 'vue-final-form';
import ModalFooter from '@/components/ui/modals/ModalFooter';
import ModalNavigation from '@/mixins/ModalNavigation';
import AmenityMixin from '@/mixins/AmenityMixin';
import ValidatorMixin from '@/components/form/ValidatorMixin';
import InitializeFormMixin from '@/components/form/InitializeFormMixin';
import TextField from '@/components/form/TextField';
import SelectInput from '@/components/form/SelectInput';
import AmenityAmountField from '@/components/amenities/list/fields/AmenityAmountField';
import AmenityPriceField from '@/components/amenities/list/fields/AmenityPriceField';
import { initialValues } from '@/views/amenities/groups/constants';
import TotalPanel from '@/components/amenities/TotalPanel';
import List from '@/components/list/List';

export default {
    name: 'GroupFormCL',

    components: {
        ModalFooter,
        FinalForm,
        TextField,
        SelectInput,
        TotalPanel,
        List,
        AmenityAmountField,
        AmenityPriceField,
    },

    mixins: [ModalNavigation, ValidatorMixin, AmenityMixin, InitializeFormMixin],

    props: {
        editMode: {
            type: Boolean,
            required: true,
        },

        onSubmit: {
            type: Function,
            required: true,
        },

        initialValues: {
            type: Object,
            default: () => ({}),
        },

        chargeCodesOptions: {
            type: Array,
            required: true,
        },
    },

    data() {
        return {
            columns: [
                {
                    name: 'name',
                    title: 'name',
                    class: 'w-5/12',
                },
                {
                    name: 'pricingUnit',
                    title: 'per',
                    class: 'w-1/12',
                },
                {
                    name: 'amount',
                    title: 'qty',
                    class: 'w-3/12',
                },
                {
                    name: 'price',
                    title: 'price w/o discount',
                    class: 'w-3/12 text-right',
                },
            ],

            items: [],
        };
    },

    computed: {
        footer() {
            return {
                primaryButton: 'save',
                tertiaryButton: 'cancel',
            };
        },

        formValues() {
            return this.$refs.form?.formState.values;
        },

        finalForm() {
            return this.$refs.form?.finalForm;
        },

        initialAmenity() {
            return initialValues.groupAmenities[0];
        },
    },

    mounted() {
        this.initializeWatchers();

        this.formUnsubscribe = this.finalForm.subscribe(
            formState => {
                this.items = formState.values.groupAmenities;
            },
            { values: true }
        );
    },

    methods: {
        initializeWatchers() {
            this.$watch(
                () => [this.formValues.globalPrice, this.formValues.globalDiscount, this.formValues.discount],
                ([globalPrice, globalDiscount, discount]) => {
                    this.$refs.form.finalForm.change(
                        'total',
                        this.getTotal(
                            globalPrice,
                            { discount: globalDiscount, type: this.formValues.globalDiscountType },
                            { discount, type: this.formValues.discountType }
                        )
                    );
                },
                {
                    immediate: true,
                }
            );

            const unwatch = this.$watch('initialValues', function () {
                this.$watch(
                    () => this.formValues.discountType,
                    () => this.finalForm.change('discount', 0)
                );
                if (unwatch) {
                    unwatch();
                }
            });

            this.$watch(
                () => this.formValues.price,
                price => {
                    if (price < 0) {
                        this.finalForm.change('discount', 0);
                    }
                }
            );
        },
    },

    deforeDestroy() {
        this.formUnsubscribe();
    },
};
</script>

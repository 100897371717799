<template>
    <div class="h-full flex flex-col">
        <FilterBlock
            ref="filter"
            :filters="[]"
            :values="filterValues"
            search-placeholder-text="Search by name and price"
            class="mb-4"
            @change="setFilterValues"
        />
        <InfiniteTable
            :key="communityId"
            class="h-full"
            :data-provider="$amntDataProvider"
            :resource="communityLevel ? 'communityAmenities' : 'amenities'"
            resource-label="individual amenities"
            :columns="columns"
            :filters="apiFilters"
            @click="handleRowClick"
        >
            <template #column:name="{ value }">
                <text-trimmer>
                    <TextHighlighter
                        :text="value"
                        :query="apiFilters.q || ''"
                        words-mode
                    />
                </text-trimmer>
            </template>

            <template #[groupsAttributeName]="{ value }">
                <AssignedGroups
                    :groups="getGroups(value)"
                    :level="communityLevel ? 'COMMUNITY' : 'ENTERPRISE'"
                />
            </template>

            <template
                v-if="!communityLevel"
                #column:pricingUnit="{ value }"
            >
                {{ getPricingUnitLabel(value) }}
            </template>

            <template #column:assignments="{ item: { assignments, groups, communityGroups } }">
                <template v-if="!assignmentsEmpty(assignments)">
                    <div
                        v-for="(label, index) in getAssignmentsLabels(assignments, groups || communityGroups)"
                        :key="index"
                    >
                        {{ label }}
                    </div>
                </template>
                <template v-else>
                    0
                </template>
            </template>

            <template #column:discount="{ item: { discount, discountType } }">
                <amount-input
                    :value="discount"
                    v-bind="getDiscountProps(discountType)"
                    read-only
                />
            </template>

            <template #column:total="{ value }">
                <amount-input
                    :value="value"
                    prefix="$"
                    read-only
                >
                    <template #default="{ maskedValue }">
                        <TextHighlighter
                            :text="maskedValue"
                            :query="maskedQuery"
                            words-mode
                        />
                    </template>
                </amount-input>
            </template>
        </InfiniteTable>
    </div>
</template>

<script>
import { words, isEqual } from 'lodash-es';
import InfiniteTable from '@/components/list/InfiniteTable';
import ModalNavigation from '@/mixins/ModalNavigation';
import NotifyMixin from '@/mixins/NotifyMixin';
import AmenityMixin from '@/mixins/AmenityMixin';
import AmountInput from '@/components/ui/AmountInput';
import AuthMixin from '@/components/auth/AuthMixin';
import TextTrimmer from '@/components/ui/TextTrimmer';
import AssignedGroups from '@/components/amenities/AssignedGroups';
import FilterBlock from '@/components/list/FilterBlock';
import TextHighlighter from '@/components/ui/TextHighlighter';
import { maskQuery } from '@/utils/Amount';

export default {
    components: {
        AssignedGroups,
        InfiniteTable,
        AmountInput,
        TextTrimmer,
        FilterBlock,
        TextHighlighter,
    },

    mixins: [ModalNavigation, NotifyMixin, AmenityMixin, AuthMixin],

    data: function () {
        return {
            loading: false,
            filterValues: {},
        };
    },

    computed: {
        columns() {
            return this.communityLevel
                ? [
                    {
                        name: 'name',
                        title: 'name',
                        class: 'w-4/12',
                    },
                    {
                        name: 'communityGroups',
                        title: 'group membership',
                        class: 'w-2/12',
                    },
                    {
                        name: 'assignments',
                        title: 'assigned units',
                        class: 'w-2/12',
                    },
                    {
                        name: 'discount',
                        title: 'community discount',
                        class: 'text-right w-2/12',
                    },
                    {
                        name: 'total',
                        title: 'price with discount(s)',
                        class: 'text-right w-2/12',
                    },
                ]
                : [
                    {
                        name: 'name',
                        title: 'name',
                        class: 'w-3/12',
                    },
                    {
                        name: 'groups',
                        title: 'group membership',
                        class: 'w-2/12',
                    },
                    {
                        name: 'assignments',
                        title: 'assignment',
                        class: 'w-3/12',
                    },
                    {
                        name: 'pricingUnit',
                        title: 'per qty',
                        class: 'w-1/12',
                    },
                    {
                        name: 'discount',
                        title: 'discount',
                        class: 'text-right w-1/12',
                    },
                    {
                        name: 'total',
                        title: 'price with discount',
                        class: 'text-right w-2/12',
                    },
                ];
        },

        communityId() {
            return this.community?.id;
        },

        communityLevel() {
            return this.$route.name === 'amenities.community.individual.index';
        },

        groupsAttributeName() {
            return this.communityLevel ? 'column:communityGroups' : 'column:groups';
        },

        apiFilters() {
            const filters = {};

            if (this.filterValues.searchQuery) {
                filters.q = this.filterValues.searchQuery;
            }

            return filters;
        },

        maskedQuery() {
            if (!this.apiFilters.q) {
                return '';
            }

            return maskQuery(this.apiFilters.q);
        },
    },

    methods: {
        handleRowClick(value) {
            this.$router.push({
                name: this.communityLevel ? 'amenities.community.individual.edit' : 'amenities.enterprise.individual.edit',
                params: {
                    amenityId: value.id,
                },
            });
        },

        getGroups(value) {
            if (this.communityLevel) {
                return value.map(({ id, group }) => ({ id, name: group.name }));
            }

            return value;
        },

        setFilterValues(values) {
            if (isEqual(this.filterValues, values)) {
                return;
            }

            if (values.searchQuery !== this.filterValues.searchQuery && words(values.searchQuery, /[^,\s]+/g).length > 5) {
                this.$refs.filter.$refs.form.finalForm.change('searchQuery', this.filterValues.searchQuery);

                return;
            }

            this.filterValues = values;
        },
    },
};
</script>

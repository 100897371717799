<template>
    <div class="flex flex-col h-full">
        <WizardIndicator
            :pages="pages"
            :current-step="currentStep"
        />
        <div
            v-if="currentStep === 0"
            class="flex flex-grow min-h-0"
        >
            <div class="flex flex-col bg-blue-50 w-1/3 mr-6 p-4 font-frank">
                <div class="text-xl font-medium text-black mb-4">
                    select individual amenities and groups
                </div>
                <div class="info">
                    You can select all the required amenities <span v-if="preselectedAmenities.length > 0">including the current one</span>
                </div>
            </div>
            <div class="w-2/3">
                <div
                    v-if="amenities.length === 0"
                    class="flex flex-col items-center justify-center bg-blue-100 border border-blue-300 text-blue-700 py-6"
                >
                    <div>
                        <span
                            class="inline-flex items-center justify-center w-4 h-4 border-2 border-blue-700 rounded-full text-xs font-frank font-600 mr-2"
                        >!</span>
                        <span class="font-frank font-medium text-sm">no individual amenities and amenity groups yet</span>
                    </div>
                </div>
                <AmenitySelector
                    v-else
                    v-model="selectedAmenities"
                    :amenities="amenities"
                    :amenity-link="hasPermission('CL_AV') && 'amenities.community.individual.edit'"
                    :group-link="hasPermission('CL_AGV') && 'amenities.community.groups.edit'"
                    group-list-resource="locationsAssignmentsGroupItems"
                    level="COMMUNITY"
                />
            </div>
        </div>
        <div
            v-if="currentStep === 1"
            class="flex flex-grow h-full"
        >
            <div class="flex flex-col bg-blue-50 w-1/3 mr-6 p-4 font-frank">
                <div class="text-xl font-medium text-black mb-4">
                    select units
                </div>
                <div class="info">
                    Use search to select the required unit or all building at once from the dropdown
                </div>
            </div>
            <div class="w-2/3">
                <LocationSearch v-model="selectedLocations" />
            </div>
        </div>
        <ModalFooter
            :footer="footer"
            :disable-primary="nextButtonDisabled"
            :primary="handleNext"
            :secondary="handleBack"
            :tertiary="close"
        />
        <Loader :loading="loading" />
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import ModalNavigation from '@/mixins/ModalNavigation';
import NotifyMixin from '@/mixins/NotifyMixin';
import WizardIndicator from '@/components/ui/wizard/WizardIndicator';
import ModalFooter from '@/components/ui/modals/ModalFooter';
import AmenitySelector from '@/components/amenities/AmenitySelector';
import LocationSearch from '@/components/amenities/LocationSearch';
import Loader from '@/components/ui/Loader';

export default {
    components: {
        Loader,
        LocationSearch,
        WizardIndicator,
        AmenitySelector,
        ModalFooter,
    },

    mixins: [ModalNavigation, NotifyMixin],

    data() {
        return {
            loading: false,
            pages: [{ title: '1. individual amenities and groups' }, { title: '2. units' }],
            currentStep: 0,
            amenities: [],
            selectedAmenities: [],
            selectedLocations: [],
        };
    },

    computed: {
        ...mapGetters({
            hasPermission: 'amnt/hasPermission',
        }),

        preselectedAmenities() {
            return [this.$route.params.amenityId, this.$route.params.groupId].filter(it => it);
        },

        footer() {
            switch (this.currentStep) {
            case 1:
                return {
                    primaryButton: 'save',
                    secondaryButton: 'back',
                    tertiaryButton: 'cancel',
                };

            default:
                return {
                    primaryButton: 'next',
                    tertiaryButton: 'cancel',
                };
            }
        },

        nextButtonDisabled() {
            switch (this.currentStep) {
            case 0:
                return this.selectedAmenities.length === 0;

            case 1:
                return this.selectedLocations.length === 0;

            default:
                return true;
            }
        },
    },

    mounted() {
        this.resetAssignmentProcess();

        this.loading = true;

        Promise.all([this.fetchAmenities()]).finally(() => (this.loading = false));
    },

    methods: {
        fetchAmenities() {
            return this.$amntDataProvider
                .getList('locationsAssignments')
                .then(amenities => (this.amenities = amenities))
                .catch(error => this.notifyError(error.message));
        },

        resetAssignmentProcess() {
            this.selectedAmenities = this.preselectedAmenities;

            this.selectedLocations = [];
            this.currentStep = 0;
        },

        updateAssignments() {
            this.loading = true;
            return this.$amntDataProvider
                .create('locationsAssignments', {
                    data: {
                        assignmentIds: this.selectedAmenities,
                        locationIds: this.selectedLocations.map(loc => loc.id),
                    },
                })
                .then(() => {
                    this.notifySuccess('Location assignment updated successfully');
                    this.resetAssignmentProcess();
                })
                .catch(error => this.notifyError(error.message))
                .finally(() => {
                    this.loading = false;
                });
        },

        handleNext() {
            switch (this.currentStep) {
            case 1:
                this.updateAssignments();
                break;

            default:
                this.currentStep += 1;
            }
        },

        handleBack() {
            this.currentStep -= 1;
        },
    },
};
</script>

<style scoped>
.info {
    @apply font-normal text-2sm;
    color: #0064a7;
}
</style>

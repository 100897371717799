<template>
    <div class="h-full">
        <div class="flex h-full">
            <div class="flex flex-col bg-gray-200 w-1/3 mr-6 p-4">
                <h1 class="font-frank text-sm font-bold text-black mb-2">
                    communities
                </h1>
                <text-input
                    v-if="communities.length"
                    class="mb-4"
                    placeholder="Search by communities"
                    :value="communityQuery"
                    clearable
                    @input="handleCommunitySearch"
                    @clear="handleClear"
                >
                    <template #leading-icon>
                        <Icon
                            name="search2"
                            class="w-4 h-4 text-graphite-800"
                        />
                    </template>
                </text-input>
                <ul
                    v-if="filteredCommunities.length"
                    class="overflow-y-auto"
                >
                    <li
                        v-for="community in filteredCommunities"
                        :key="community.id"
                        class="nav-tab"
                        :class="{ 'nav-tab--active': activeCommunity === community.id }"
                        @click="handleCommunityClick(community.id)"
                    >
                        <TextHighlighter
                            :text="community.name"
                            :query="communityQuery"
                            words-mode
                        />
                    </li>
                </ul>
                <div
                    v-if="filteredCommunities.length === 0"
                    class="text-active-800"
                    :class="{ 'text-center': communityQuery }"
                >
                    <icon
                        v-if="!communityQuery"
                        name="info"
                        class="h-4 w-4 inline mr-1"
                    />
                    <span class="font-frank font-medium text-sm">{{ noCommunitiesLabel }}</span>
                </div>
            </div>
            <div class="flex flex-col w-2/3">
                <div
                    v-if="amenities.length === 0"
                    class="flex flex-col items-center justify-center bg-blue-100 border border-blue-300 text-blue-700 py-6"
                >
                    <div>
                        <span
                            class="inline-flex items-center justify-center w-4 h-4 border-2 border-blue-700 rounded-full text-xs font-frank font-600 mr-2"
                        >!</span>
                        <span class="font-frank font-medium text-sm">no individual amenities and amenity groups yet</span>
                    </div>
                    <div class="font-frank font-medium text-sm mt-2">
                        <router-link
                            v-if="hasPermission('EL_AM')"
                            :to="{ name: 'amenities.enterprise.individual.create' }"
                            class="assignment-link underline mr-4"
                        >
                            create individual amenity
                        </router-link>
                        <router-link
                            v-if="hasPermission('EL_AGM')"
                            :to="{ name: 'amenities.enterprise.groups.create' }"
                            class="assignment-link underline"
                        >
                            create amenity group
                        </router-link>
                    </div>
                </div>
                <template v-else>
                    <FilterBlock
                        ref="filter"
                        :filters="[]"
                        :values="filterValues"
                        search-placeholder-text="Search by name and price"
                        controls-class="w-1/2"
                        @change="setFilterValues"
                    />
                    <AmenitySelector
                        v-model="assignments"
                        :amenities="amenitiesWithLocations"
                        :amenity-link="hasPermission('EL_AV') && 'amenities.enterprise.individual.edit'"
                        :group-link="hasPermission('EL_AGV') && 'amenities.enterprise.groups.edit'"
                        group-list-resource="communitiesAssignmentsGroupItems"
                        :query="query"
                        class="overflow-hidden"
                    >
                        <div class="communities-assignment__configuration">
                            <span class="mr-2 font-frank text-2sm text-black font-medium tracking-wider">configuration management:</span>
                            <editable-field
                                :initial-values="{ configuration: initialConfiguration }"
                                :on-submit="handleConfigurationUpdate"
                                :with-edit-control="false"
                                cancel-on-outside
                                @edit-mode-change="handleConfigurationOpen"
                            >
                                <template
                                    #field="{
                                        editMode,
                                        formProps: {
                                            values: { configuration },
                                        },
                                        clickHandler,
                                    }"
                                >
                                    <button
                                        v-if="!editMode"
                                        class="btn-primary btn-transparent align-text-top"
                                        type="button"
                                        :disabled="configurationStatus === ASSIGNMENT_STATUSES.IN_PROGRESS"
                                        @click.stop="clickHandler"
                                    >
                                        {{ getConfigurationLabel(configuration) }}
                                    </button>
                                    <select-input
                                        v-else
                                        name="configuration"
                                        :options="configurationOptions"
                                        :edit-mode="true"
                                        placeholder="Select"
                                        class="form-col__select"
                                        :searchable="false"
                                    />
                                </template>
                                <template #cancel="{ cancelHandler }">
                                    <button
                                        type="button"
                                        class="btn btn-transparent text-graphite-800 ml-4"
                                        @click.stop="cancelHandler"
                                    >
                                        cancel
                                    </button>
                                </template>
                                <template
                                    #save="{
                                        formProps: {
                                            values: { configuration },
                                        },
                                    }"
                                >
                                    <button
                                        v-show="configuration != initialConfiguration"
                                        class="btn-primary btn-transparent ml-4"
                                    >
                                        apply
                                    </button>
                                </template>
                            </editable-field>
                            <div class="ml-1">
                                <AssignmentStatus :status="configurationStatus" />
                            </div>
                        </div>
                    </AmenitySelector>
                </template>
            </div>
        </div>
        <modal-footer
            :footer="footer"
            :tertiary="close"
            :primary="handleSubmit"
        />
        <Loader :loading="loading" />
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { isEqual, words } from 'lodash-es';
import ModalFooter from '@/components/ui/modals/ModalFooter';
import Icon from '@/components/ui/Icon';
import ModalNavigation from '@/mixins/ModalNavigation';
import NotifyMixin from '@/mixins/NotifyMixin';
import AmenityMixin from '@/mixins/AmenityMixin';
import ConfirmationMixin from '@/mixins/ConfirmationMixin';
import AmenitySelector from '@/components/amenities/AmenitySelector';
import Loader from '@/components/ui/Loader';
import SelectInput from '@/components/form/SelectInput';
import EditableField from '@/components/ui/inlineediting/EditableField';
import AssignmentStatus, { ASSIGNMENT_STATUSES } from '@/components/ui/AssignmentStatus';
import FilterBlock from '@/components/list/FilterBlock';
import TextHighlighter from '@/components/ui/TextHighlighter';
import TextInput from '@/components/ui/TextInput';
import searchQuery from './searchQuery';

export default {
    name: 'CommunitiesAssignment',

    components: {
        Loader,
        AmenitySelector,
        ModalFooter,
        Icon,
        SelectInput,
        EditableField,
        AssignmentStatus,
        FilterBlock,
        TextHighlighter,
        TextInput,
    },

    mixins: [ModalNavigation, ConfirmationMixin, NotifyMixin, AmenityMixin],

    data: () => {
        return {
            activeCommunity: null,
            loading: false,
            initialConfiguration: null,
            configurationStatus: null,
            communities: [],
            amenities: [],
            locations: [],
            assignments: [],
            footer: {
                primaryButton: 'save',
                tertiaryButton: 'cancel',
            },

            filterValues: {},
            communityQuery: '',
        };
    },

    computed: {
        ...mapGetters({
            hasPermission: 'amnt/hasPermission',
        }),

        inactiveCommunities() {
            return this.communities.filter(({ id }) => id !== this.activeCommunity);
        },

        configurationOptions() {
            const communitiesOptions = this.inactiveCommunities.map(({ id: key, name: value }) => ({ key, value }));
            let options = [
                { $isSeparator: true, value: 'general options' },
                { key: 'all', value: 'Select all' },
                { key: 'none', value: 'Unselect all' },
            ];

            if (communitiesOptions.length) {
                options.push({ $isSeparator: true, value: 'copy community configuration' }, ...communitiesOptions);
            }

            return options;
        },

        initialAssignments() {
            return this.getAmenitiesIdsFromLocations(this.locations);
        },

        amenitiesWithLocations() {
            return this.filteredAmenities.map(amenity => ({
                ...amenity,
                unitsAmount: this.locations.find(({ assignmentId }) => assignmentId === amenity.id)?.locations.length ?? 0,
            }));
        },

        filteredAmenities() {
            if (this.query) {
                return searchQuery(this.query, this.amenities, { name: 'string', total: 'amount' });
            }

            return this.amenities;
        },

        filteredCommunities() {
            if (this.communityQuery) {
                return searchQuery(this.communityQuery, this.communities, { name: 'string' });
            }

            return this.communities;
        },

        unassignedLocations() {
            return new Set(
                this.locations.reduce((acc, { assignmentId, locations }) => {
                    if (!this.assignments.includes(assignmentId)) {
                        return [...acc, ...locations.map(({ id }) => id)];
                    }

                    return acc;
                }, [])
            ).size;
        },

        query() {
            return this.filterValues.searchQuery;
        },

        noCommunitiesLabel() {
            return this.communityQuery ? 'no results' : 'no communities are created yet';
        },
    },

    watch: {
        activeCommunity(val) {
            if (Object.keys(this.filterValues).length) {
                this.filterValues = {};
                this.$refs.filter.$refs.form.finalForm.initialize(this.filterValues);
            }
            this.loading = true;
            this.unwatchAssignmentsProp?.();
            this.resetConfiguration();
            this.fetchAssignments(val).finally(() => (this.loading = false));
        },
    },

    mounted() {
        this.loading = true;

        Promise.all([this.fetchCommunities(), this.fetchAmenities()]).finally(() => (this.loading = false));
    },

    created() {
        this.ASSIGNMENT_STATUSES = ASSIGNMENT_STATUSES;
    },

    methods: {
        fetchCommunities() {
            return this.$amntDataProvider
                .getList('communities')
                .then(communities => {
                    this.communities = communities;
                    this.activeCommunity = this.communities[0].id;
                })
                .catch(error => this.notifyError(error.message));
        },

        fetchAmenities() {
            return this.$amntDataProvider
                .getList('communitiesAssignments')
                .then(amenities => (this.amenities = amenities))
                .catch(error => this.notifyError(error.message));
        },

        fetchAssignments(communityId) {
            return this.$amntDataProvider
                .getList('communityAssignments', { communityId })
                .then(locations => {
                    this.locations = locations;
                    this.assignments = this.initialAssignments;
                })
                .catch(error => this.notifyError(error.message));
        },

        updateAssignments() {
            return this.$amntDataProvider
                .update('communityAssignments', {
                    data: this.assignments,
                    communityId: this.activeCommunity,
                })
                .then(initialAssignments => {
                    this.notifySuccess('Community assignment updated successfully');
                    this.locations = this.updateLocations(initialAssignments);

                    this.assignments = initialAssignments;
                });
        },

        updateLocations(assignmentIds) {
            return assignmentIds.map(assignmentId => {
                const location = this.locations.find(location => location.assignmentId === assignmentId);

                if (location) {
                    return location;
                }

                return {
                    assignmentId,
                    locations: [],
                };
            });
        },

        fetchGroupItems(groupId) {
            if (this.groupItems[groupId]) {
                return;
            }

            this.$amntDataProvider
                .getList('communitiesAssignmentsGroupItems', { groupId })
                .then(
                    groupItems =>
                        (this.groupItems = {
                            ...this.groupItems,
                            [groupId]: groupItems,
                        })
                )
                .catch(error => this.notifyError(error.message));
        },

        handleCommunityClick(communityId) {
            if (this.activeCommunity === communityId) {
                return;
            }

            if (isEqual(new Set(this.initialAssignments), new Set(this.assignments))) {
                this.activeCommunity = communityId;

                return;
            }

            this.requestConfirmation({
                confirmationMessage: `do you want to save changes for the ${this.getCommunityName(
                    this.activeCommunity
                )} community before switching to the other?`,
                confirmBtnText: 'yes, save',
                cancelBtnText: 'discard changes',
                confirmationType: 'success',
            }).then(confirmed => {
                if (confirmed) {
                    if (this.unassignedLocations) {
                        this.requestConfirmation({
                            confirmationMessage: `when a new configuration is applied, ${this.getUnitsLabel(
                                this.unassignedLocations
                            )} will be impacted as their Amenity configuration will lose one or several Amenity/Amenity groups. Please check the list of items to be unassigned. Are you sure you want to continue?`,
                            confirmBtnText: 'yes, change configuration',
                            cancelBtnText: 'no, go back',
                            confirmationType: 'success',
                        }).then(confirmed => {
                            if (confirmed) {
                                this.loading = true;
                                this.updateAssignments()
                                    .then(() => (this.activeCommunity = communityId))
                                    .catch(error => {
                                        this.notifyError(error.message);
                                        this.loading = false;
                                    });
                            }
                        });
                    } else {
                        this.loading = true;
                        this.updateAssignments()
                            .then(() => (this.activeCommunity = communityId))
                            .catch(error => {
                                this.notifyError(error.message);
                                this.loading = false;
                            });
                    }
                } else {
                    this.activeCommunity = communityId;
                }
            });
        },

        handleSubmit() {
            if (this.loading) {
                return;
            }

            if (this.unassignedLocations) {
                this.requestConfirmation({
                    confirmationMessage: `when a new configuration is applied, ${this.getUnitsLabel(
                        this.unassignedLocations
                    )} will be impacted as their Amenity configuration will lose one or several Amenity/Amenity groups. Please check the list of items to be unassigned. Are you sure you want to continue?`,
                    confirmBtnText: 'yes, change configuration',
                    cancelBtnText: 'no, go back',
                    confirmationType: 'success',
                }).then(confirmed => {
                    if (confirmed) {
                        this.loading = true;
                        this.updateAssignments()
                            .catch(error => this.notifyError(error.message))
                            .finally(() => (this.loading = false));
                    }
                });
            } else {
                this.loading = true;
                this.updateAssignments()
                    .catch(error => this.notifyError(error.message))
                    .finally(() => (this.loading = false));
            }
        },

        getCommunityName(communityId) {
            return this.communities.find(({ id }) => communityId === id)?.name || '';
        },

        handleConfigurationUpdate({ configuration }) {
            this.unwatchAssignmentsProp?.();

            if (!configuration) {
                this.initialConfiguration = null;
                return;
            }

            this.configurationStatus = ASSIGNMENT_STATUSES.IN_PROGRESS;

            switch (configuration) {
            case 'all':
                this.applyConfiguration(
                    configuration,
                    this.amenities.map(({ id }) => id)
                );
                break;
            case 'none':
                this.applyConfiguration(configuration);
                break;
            default:
                this.$amntDataProvider
                    .getList('communityAssignments', { communityId: configuration })
                    .then(locations => {
                        this.applyConfiguration(configuration, this.getAmenitiesIdsFromLocations(locations));
                    })
                    .catch(error => {
                        this.notifyError(error.message);
                        this.resetConfiguration();
                    });
            }
        },

        applyConfiguration(configuration, assignments = []) {
            this.assignments = assignments;
            this.initialConfiguration = configuration;
            this.configurationStatus = ASSIGNMENT_STATUSES.SUCCESS;

            setTimeout(() => (this.configurationStatus = null), 3000);

            this.unwatchAssignmentsProp = this.$watch('assignments', () => {
                if (this.initialConfiguration) {
                    this.resetConfiguration();
                    this.unwatchAssignmentsProp();
                }
            });
        },

        resetConfiguration() {
            this.initialConfiguration = null;
            this.configurationStatus = null;
        },

        getConfigurationLabel(configuration) {
            if (!configuration) {
                return 'select option';
            }

            switch (configuration) {
            case 'all':
                return 'selected all';
            case 'none':
                return 'unselected all';
            default:
                return `selected ${this.configurationOptions.find(({ key }) => key === configuration)?.value.toLowerCase() || ''}`;
            }
        },

        handleConfigurationOpen(editMode) {
            if (editMode) {
                this.configurationStatus = null;
            }
        },

        getAmenitiesIdsFromLocations(locations) {
            return locations.map(({ assignmentId }) => assignmentId);
        },

        setFilterValues(values) {
            if (isEqual(this.filterValues, values)) {
                return;
            }

            if (values.searchQuery !== this.query && words(values.searchQuery, /[^,\s]+/g).length > 5) {
                this.$refs.filter.$refs.form.finalForm.change('searchQuery', this.query);

                return;
            }

            this.filterValues = values;
        },

        handleCommunitySearch(value) {
            this.communityQuery = value;
        },

        handleClear() {
            this.communityQuery = '';
        },
    },
};
</script>

<style scoped>
.nav-tab {
    @apply flex flex-grow border border-gray-300 p-2 cursor-pointer text-xs text-black mb-2;
}

.nav-tab:hover {
    @apply text-white;
    background-color: var(--highlightColor600);
    border-color: var(--highlightColor600);
}

.nav-tab:last-child {
    @apply mb-0;
}

.nav-tab--active {
    color: var(--highlightColor500);
    border-color: var(--highlightColor500);
}

.communities-assignment__configuration {
    @apply flex items-center;
    min-height: 40px;
}

.form-col__select {
    min-width: 280px;
}
</style>

import { getOnlyDigits, getPrecision, unmask } from '@/utils/Amount';
import { round } from '@/utils/Numbers';

export default function searchQuery(query, items) {
    const preparedQuery = query.toLowerCase();
    const querySet = preparedQuery.split(' ').filter(Boolean);
    let queryMap = {};

    for (const queryItem of querySet) {
        if (Object.hasOwn(queryMap, queryItem)) {
            queryMap[queryItem].query++;
        } else {
            queryMap[queryItem] = {
                query: 1,
                actual: 0,
            };
        }
    }

    const result = items.filter(({ name, total }) => {
        for (const queryKey in queryMap) {
            queryMap[queryKey].actual = name.toLowerCase().split(queryKey).length - 1;

            if (getOnlyDigits(queryKey)) {
                const precision = getPrecision(queryKey);
                const rawQueryPrice = Math.abs(unmask(queryKey, precision));
                const price = Math.abs(total);

                switch (precision) {
                case 2:
                    if (price === rawQueryPrice) {
                        queryMap[queryKey].actual++;
                    }
                    break;

                case 1:
                    if (price >= rawQueryPrice && price <= round(rawQueryPrice + 0.09, 2)) {
                        queryMap[queryKey].actual++;
                    }
                    break;

                case 0:
                    if (price >= rawQueryPrice && price <= rawQueryPrice + 0.99) {
                        queryMap[queryKey].actual++;
                    }
                }
            }

            if (queryMap[queryKey].actual < queryMap[queryKey].query) {
                return false;
            }
        }

        return true;
    });

    return result;
}

<template>
    <div class="h-full">
        <GroupFormEL
            :edit-mode="hasPermission('EL_AGM')"
            :initial-values="initialValues"
            :amenities="amenities"
            :on-submit="handleSubmit"
            :validate="validate"
            :error="error"
        />
        <loader
            :loading="loading"
            :backdrop="true"
        />
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import EventBus from '@/utils/EventBus';
import Loader from '@/components/ui/Loader';
import ValidatorMixin from '@/components/form/ValidatorMixin';
import NotifyMixin from '@/mixins/NotifyMixin';
import ModalNavigation from '@/mixins/ModalNavigation';
import ActionsMixin from '@/mixins/ActionsMixin';
import GroupFormEL from '@/views/amenities/groups/GroupFormEL';
import { initialValues } from '@/views/amenities/groups/constants';

export default {
    name: 'GroupDetailsEL',

    components: {
        GroupFormEL,
        Loader,
    },

    mixins: [NotifyMixin, ModalNavigation, ValidatorMixin, ActionsMixin],

    data() {
        return {
            loading: false,
            error: null,
            initialValues,
            amenities: [],
        };
    },

    computed: {
        ...mapGetters({
            hasPermission: 'amnt/hasPermission',
        }),

        groupId() {
            return this.$route.params.groupId;
        },
    },

    mounted() {
        this.loading = true;

        Promise.all([this.fetchAmenities(), this.groupId && this.fetchGroup()])
            .catch(error => this.notifyError(error.message))
            .finally(() => (this.loading = false));

        const actionId = 'amnt-delete-group';

        if (this.hasConfirmAction(actionId)) {
            this.removeConfirmAction(actionId);
        } else {
            this.removeActionFromStore({
                title: 'delete group',
                routeName: this.$route.name,
            });
        }

        if (this.groupId && this.hasPermission('EL_AGM')) {
            this.addConfirmAction(
                {
                    id: actionId,
                    title: 'delete group',
                    showConfirmation: true,
                    confirmationMessage: 'are you sure you want to delete this group?',
                    confirmBtnText: 'yes, delete',
                    cancelBtnText: 'no, go back',
                    confirmationType: 'warning',
                },
                this.deleteGroup
            );
        }

        EventBus.on('confirm-assign-community', this.saveBeforeAssignRedirect);
    },

    beforeUnmount() {
        EventBus.off('confirm-assign-community');
    },

    methods: {
        async submitForm(values) {
            const { name, description, discount, discountType, groupAmenities } = values;
            const items = groupAmenities.map(({ amenityId, amount, pricingUnit }) => ({
                amenityId,
                amount: pricingUnit || !this.groupId ? amount : null,
            }));
            const data = {
                name,
                description,
                discount,
                discountType,
                items,
            };
            let result;

            if (this.groupId) {
                result = await this.$amntDataProvider.update('groups', { id: this.groupId, data });
                this.notifySuccess('Amenity group has been updated');
            } else {
                result = await this.$amntDataProvider.create('groups', { data });
                this.notifySuccess('Amenity group was successfully created');
            }

            return result;
        },

        async handleSubmit(values) {
            if (this.loading) {
                return;
            }

            this.loading = true;

            try {
                if (this.groupId) {
                    const { name } = await this.submitForm(values);
                    this.setActiveModalName(name);
                } else {
                    await this.submitForm(values);
                    this.close();
                }
                this.error = null;
            } catch (error) {
                this.notifyError(error.message);
                this.error = error;
            } finally {
                this.loading = false;
            }
        },

        validate(values) {
            return {
                generalGroupAmenities: this.notEmpty(values.groupAmenities),
            };
        },

        fetchAmenities() {
            return this.$amntDataProvider.get('amenities').then(({ items }) => {
                this.amenities = items;
            });
        },

        fetchGroup() {
            return this.$amntDataProvider.getOne('groups', { id: this.groupId }).then(({ name, items, ...restValues }) => {
                const groupAmenities = items.map(({ amount, amenity: { id: amenityId, name, pricingUnit, price } }) => ({
                    amenityId,
                    name,
                    pricingUnit,
                    amount,
                    price,
                }));

                this.initialValues = {
                    name,
                    groupAmenities,
                    ...restValues,
                };

                this.setActiveModalName(name);
            });
        },

        deleteGroup() {
            this.$amntDataProvider
                .delete('groups', { id: this.groupId })
                .then(() => {
                    this.close();
                    this.notifySuccess('Group deleted');
                })
                .catch(error => this.notifyError(error.message));
        },

        saveBeforeAssignRedirect({ values }) {
            this.submitForm(values)
                .then(({ id }) => {
                    this.$router.push({
                        name: 'amenities.communities-assignment',
                        params: { id },
                    });
                    this.error = null;
                })
                .catch(error => {
                    this.notifyError(error.message);
                    this.error = error;
                });
        },
    },
};
</script>

<template>
    <div class="h-full">
        <div
            v-if="entity"
            class="w-full h-full grid grid-rows-1 col-gap-6"
            :class="level === 'ENTERPRISE' ? 'grid-cols-3' : 'grid-cols-2'"
        >
            <div
                v-if="level === 'ENTERPRISE'"
                class="flex flex-col bg-gray-100 p-4"
            >
                <div class="font-frank font-bold text-sm text-black">
                    “{{ entity.name }}” {{ type === 'GROUP' ? 'group' : 'amenity' }} was assigned to
                    {{ getCommunitiesLabel(totalCommunities) }}
                </div>
                <div
                    v-if="communities.length < totalCommunities"
                    class="mt-1 text-active-800"
                >
                    <Icon
                        name="info"
                        class="h-4 w-4 inline mr-1"
                    />
                    <span class="font-frank font-medium text-xs">{{
                        $tc('amnt.assigning.visibleCommunities', communities.length, {
                            visible: communities.length,
                            total: getCommunitiesLabel(totalCommunities),
                        })
                    }}</span>
                </div>
                <div class="mt-2 mb-4">
                    <input
                        v-model="communityFilter"
                        type="text"
                        placeholder="Search by communities"
                        class="w-full border text-sm p-3"
                    >
                </div>
                <div
                    v-if="filteredCommunities.length === 0"
                    class="font-frank font-medium text-sm text-active-800 text-center"
                >
                    no results
                </div>
                <GroupSelector
                    v-model="selectedCommunityId"
                    :items="filteredCommunities"
                    counter="count"
                />
            </div>

            <div
                v-if="buildings"
                class="col-span-2 flex flex-col"
            >
                <div class="font-frank font-bold text-sm text-black mb-4">
                    <template v-if="level === 'ENTERPRISE'">
                        {{ getUnitsLabel(assignedUnits) }} assigned
                    </template>
                    <template v-else>
                        <template v-if="type === 'GROUP'">
                            “{{ entity.group.name }}” group
                        </template>
                        <template v-else>
                            “{{ entity.amenity.name }}” individual amenity
                        </template> was assigned to
                        {{ getUnitsLabel(assignedUnits) }}
                    </template>
                </div>
                <TwoLevelList
                    :columns="columns"
                    :items="buildings"
                    level1-key="buildingId"
                    level2-key="id"
                    children-key="units"
                    :initial-expanded="true"
                >
                    <template #level2:assignment="{ item }">
                        <AmenityAssignmentInfo
                            :location="item"
                            :level="level"
                        />
                    </template>
                </TwoLevelList>
            </div>
        </div>
        <Loader
            :loading="loading"
            backdrop
        />
    </div>
</template>

<script>
import ModalNavigation from '@/mixins/ModalNavigation';
import NotifyMixin from '@/mixins/NotifyMixin';
import AmenityMixin from '@/mixins/AmenityMixin';
import TwoLevelList from '@/components/list/TwoLevelList';
import GroupSelector from '@/components/list/GroupSelector';
import Loader from '@/components/ui/Loader';
import AmenityAssignmentInfo from '@/components/amenities/AmenityAssignmentInfo';
import Icon from '@/components/ui/Icon';
import { initialValues } from '@/views/amenities/assignment/constants';

export default {
    name: 'AssignedCommunities',

    components: { AmenityAssignmentInfo, TwoLevelList, Loader, GroupSelector, Icon },

    mixins: [ModalNavigation, NotifyMixin, AmenityMixin],

    /**
     * Use beforeRouteEnter instead of 'mount' to load data in case router component is being reused in target route and so 'mount' hook not triggered
     * Fetch all the neccessary data before 'mount' hook
     */
    beforeRouteEnter(to, from, next) {
        next(vm => {
            vm.fetchData();
        });
    },

    /**
     * If neccessary, reset component state right before navigating to the route with the same route component
     */
    beforeRouteLeave(to, from, next) {
        if (
            (to.name === 'amenities.enterprise.groups.assignedCommunities' &&
                from.name === 'amenities.enterprise.individual.assignedCommunities') ||
            (to.name === 'amenities.community.groups.assignedCommunities' &&
                from.name === 'amenities.community.individual.assignedCommunities') ||
            (to.name === 'amenities.enterprise.individual.assignedCommunities' &&
                from.name === 'amenities.enterprise.groups.assignedCommunities') ||
            (to.name === 'amenities.community.individual.assignedCommunities' &&
                from.name === 'amenities.community.groups.assignedCommunities')
        ) {
            Object.assign(this.$data, initialValues);
        }

        next();
    },

    props: {
        type: {
            type: String,
            required: true,
        },

        level: {
            type: String,
            required: true,
        },
    },

    data() {
        return initialValues;
    },

    computed: {
        id() {
            switch (this.type) {
            case 'GROUP':
                return this.$route.params.groupId;

            case 'AMENITY':
                return this.$route.params.amenityId;

            default:
                return null;
            }
        },

        communityId() {
            return this.$route.params.communityId;
        },

        entityResource() {
            if (this.level === 'ENTERPRISE' && this.type === 'GROUP') {
                return 'groups';
            }

            if (this.level === 'ENTERPRISE' && this.type === 'AMENITY') {
                return 'amenities';
            }

            if (this.level === 'COMMUNITY' && this.type === 'GROUP') {
                return 'communityGroups';
            }

            if (this.level === 'COMMUNITY' && this.type === 'AMENITY') {
                return 'communityAmenities';
            }

            return null;
        },

        communitiesResource() {
            if (this.level === 'COMMUNITY') {
                return null;
            }

            switch (this.type) {
            case 'GROUP':
                return 'groupCommunities';

            case 'AMENITY':
                return 'amenityCommunities';

            default:
                return null;
            }
        },

        locationsResource() {
            if (this.level === 'ENTERPRISE' && this.type === 'GROUP') {
                return 'groupLocations';
            }

            if (this.level === 'ENTERPRISE' && this.type === 'AMENITY') {
                return 'amenityLocations';
            }

            if (this.level === 'COMMUNITY' && this.type === 'GROUP') {
                return 'communityGroupLocations';
            }

            if (this.level === 'COMMUNITY' && this.type === 'AMENITY') {
                return 'communityAmenityLocations';
            }

            return null;
        },

        columns() {
            switch (this.type) {
            case 'GROUP':
                return [
                    {
                        name: 'buildingName',
                        title: 'building',
                        class: 'w-3/12',
                        level: 1,
                    },
                    {
                        name: 'name',
                        title: 'unit',
                        level: 2,
                    },
                ];

            case 'AMENITY':
                return [
                    {
                        name: 'buildingName',
                        title: 'building',
                        class: 'w-3/12',
                        level: 1,
                    },
                    {
                        name: 'name',
                        title: 'unit',
                        class: 'w-2/12',
                        level: 2,
                    },
                    {
                        name: 'assignment',
                        title: 'assignment',
                        class: 'flex-1 flex',
                        level: 2,
                    },
                ];

            default:
                return null;
            }
        },

        filteredCommunities() {
            return this.communities.filter(community => community.name.toLowerCase().includes(this.communityFilter.toLowerCase()));
        },

        assignedUnits() {
            if (!this.buildings) {
                return 0;
            }

            return this.buildings.reduce((acc, building) => acc + building.units.length, 0);
        },
    },

    watch: {
        communities(items) {
            if (items.length === 0) {
                this.selectedCommunityId = null;
                return;
            }

            if (!items.find(community => community.id === this.selectedCommunityId)) {
                this.selectedCommunityId = items[0].id;
            }
        },

        async selectedCommunityId(communityId) {
            if (this.level === 'ENTERPRISE' && !communityId) {
                return;
            }

            this.loading = true;

            try {
                this.buildings = await this.$amntDataProvider.getList(this.locationsResource, {
                    ...this.$route.params,
                    communityId: this.selectedCommunityId,
                });
            } catch (error) {
                this.notifyError(error.message);
            } finally {
                this.loading = false;
            }
        },
    },

    methods: {
        fetchData() {
            Promise.all([
                this.$amntDataProvider.getOne(this.entityResource, { id: this.id, communityId: this.communityId }).then(entity => {
                    this.entity = entity;
                }),

                this.communitiesResource
                    ? this.$amntDataProvider
                        .getList(this.communitiesResource, this.$route.params)
                        .then(({ accessibleCommunities, communitiesTotalCount }) => {
                            this.communities = accessibleCommunities;
                            this.totalCommunities = communitiesTotalCount;
                        })
                    : this.$amntDataProvider.getList(this.locationsResource, this.$route.params).then(buildings => {
                        this.buildings = buildings;
                    }),
            ])
                .catch(error => {
                    this.notifyError(error.message);
                })
                .finally(() => {
                    this.loading = false;
                });
        },
    },
};
</script>

<template>
    <div class="h-full">
        <GroupFormCL
            :edit-mode="hasPermission('CL_AGM')"
            :initial-values="initialValues"
            :charge-codes-options="chargeCodesOptions"
            :on-submit="handleSubmit"
        />
        <loader
            :loading="loading"
            :backdrop="true"
        />
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Loader from '@/components/ui/Loader';
import ValidatorMixin from '@/components/form/ValidatorMixin';
import NotifyMixin from '@/mixins/NotifyMixin';
import ModalNavigation from '@/mixins/ModalNavigation';
import ActionsMixin from '@/mixins/ActionsMixin';
import GroupFormCL from '@/views/amenities/groups/GroupFormCL';
import { initialValues } from '@/views/amenities/groups/constants';

export default {
    name: 'GroupDetailsCL',

    components: {
        GroupFormCL,
        Loader,
    },

    mixins: [NotifyMixin, ModalNavigation, ValidatorMixin, ActionsMixin],

    data() {
        return {
            loading: false,
            initialValues,
            chargeCodesOptions: [],
        };
    },

    computed: {
        ...mapGetters({
            hasPermission: 'amnt/hasPermission',
        }),

        groupId() {
            return this.$route.params.groupId;
        },
    },

    mounted() {
        this.loading = true;

        Promise.all([this.fetchGroup(), this.fetchChargeCodes()])
            .catch(error => this.notifyError(error.message))
            .finally(() => (this.loading = false));
    },

    methods: {
        async handleSubmit({ chargeCodeId, discount, discountType }) {
            if (this.loading) {
                return;
            }

            const data = {
                chargeCodeId,
                discount,
                discountType,
            };

            this.loading = true;

            try {
                const { name } = await this.$amntDataProvider.update('communityGroups', { id: this.groupId, data });
                this.notifySuccess('Amenity group has been updated');
                this.setActiveModalName(name);
            } catch (error) {
                this.notifyError(error.message);
            } finally {
                this.loading = false;
            }
        },

        fetchGroup() {
            return this.$amntDataProvider
                .getOne('communityGroups', { id: this.groupId })
                .then(
                    ({
                        group: { name, description, items, discount: globalDiscount, discountType: globalDiscountType, price: globalPrice },
                        chargeCode,
                        total,
                        discount,
                        discountType,
                        assignments,
                    }) => {
                        const groupAmenities = items.map(({ amount, amenity: { id: amenityId, name, pricingUnit, price } }) => ({
                            amenityId,
                            name,
                            pricingUnit,
                            amount,
                            price,
                        }));

                        this.initialValues = {
                            name,
                            description,
                            globalDiscount,
                            globalDiscountType,
                            globalPrice,
                            chargeCodeId: chargeCode?.id || null,
                            total,
                            discount,
                            discountType,
                            assignments,
                            groupAmenities,
                        };

                        this.setActiveModalName(name);
                    }
                );
        },

        fetchChargeCodes() {
            return this.$amntDataProvider.get('communityChargeCodes').then(chargeCodes => {
                this.chargeCodesOptions = chargeCodes.map(({ id, description }) => ({ key: id, value: description }));
            });
        },
    },
};
</script>

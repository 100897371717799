<template>
    <div class="info-panel">
        <span class="info-panel__icon">!</span>
        <span class="info-panel__text">{{ text }}</span>
    </div>
</template>

<script>
export default {
    props: {
        text: {
            type: String,
            required: true,
        },
    },
};
</script>

<style scoped>
.info-panel {
    @apply flex items-center justify-center bg-blue-100 border border-blue-300 text-blue-700 py-6;

    &__icon {
        @apply flex items-center justify-center w-4 h-4 border-2 border-blue-700 rounded-full text-xs font-frank font-600 mr-2;
    }

    &__text {
        @apply font-frank font-medium text-sm;
    }
}
</style>

<template>
    <div
        class="search-item"
        @click="$emit('click', item)"
    >
        <Icon
            v-if="selected"
            name="checkmark"
            class="absolute left-1 m-1 ml-2 w-3 h-3 text-green-500"
        />
        <Icon
            v-if="selected"
            name="close"
            class="right-1 m-1 mr-2 absolute w-3 h-3 text-red-800"
        />

        <div>
            <TextHighlighter
                :text="item.name"
                :query="searchQuery"
                words-mode
            />, building
            <TextHighlighter
                :text="item.buildingName"
                :query="searchQuery"
                words-mode
            />
        </div>
    </div>
</template>

<script>
import TextHighlighter from '@/components/ui/TextHighlighter';
import Icon from '@/components/ui/Icon';

export default {
    components: { TextHighlighter, Icon },

    props: {
        item: {
            type: Object,
            required: true,
        },

        backLink: {
            type: Boolean,
            default: false,
        },

        personDetails: {
            type: Boolean,
            default: false,
        },

        searchQuery: {
            type: String,
            default: '',
        },

        selected: {
            type: Boolean,
            default: false,
        },

        withDetails: {
            type: Boolean,
            default: false,
        },
    },

    emits: ['click'],
};
</script>

<style scoped>
.search-item {
    @apply flex items-center h-8 px-8 text-gray-500 cursor-pointer;
}

.search-item:hover {
    @apply bg-highlight-100;
}

.search-item:deep(.highlighter .highlighted) {
    @apply bg-transparent text-black;
}
</style>

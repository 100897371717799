<template>
  <div class="tree-list">
    <div class="head">
      <div class="row">
        <div
          v-for="(column, index) in columns"
          :key="index"
          class="column"
          :class="[column.class, { sortable: column.sortable }]"
          v-on="
            column.sortable
              ? { click: () => $emit('sortClick', column.name) }
              : {}
          "
        >
          <Icon
            v-if="index === 0"
            :name="allExpanded ? 'minusSquare' : 'plusSquare'"
            class="expand-icon flex-shrink-0"
            @click="toggleAll"
          />
          <component
            v-if="isComponentField(column.title)"
            :is="column.title"
          />
          <span v-else>{{ column.title }}</span>
          <Icon
            v-if="column.sortable"
            class="flex-shrink-0 ml-2"
            :name="sortIcon(column.name)"
          />
        </div>
      </div>
    </div>

    <div
      class="body"
      v-for="level1Item in items"
      :key="level1Item[level1Key]"
    >
      <div
        class="row row--level-1"
        @click="toggleLevelExpand(level1Item)"
      >
        <div
          v-for="(column, index) in level1Columns"
          :key="column.name"
          class="column"
          :class="column.class"
        >
          <div class="w-5 flex-shrink-0">
            <Icon
              v-if="index === 0 && hasChildren(level1Item)"
              :name="isExpanded[level1Item[level1Key]] ? 'minusSquare' : 'plusSquare'"
              class="expand-icon"
            />
          </div>
          <!-- @slot List's column [column:columnName] -->
          <slot
            :name="`level1:${column.name}`"
            :item="level1Item"
            :value="rawColumnValue(level1Item, column.name)"
          >
            {{ visibleColumnValue(level1Item, column.name) }}
          </slot>
        </div>
      </div>
      <template v-if="isExpanded[level1Item[level1Key]]">
        <div
          class="row row--level-2"
          v-for="level2Item in level1Item[childrenKey]"
          :key="level2Item[level2Key]"
        >
          <div
            v-for="column in level1Columns"
            :key="column.name"
            class="column"
            :class="column.class"
          />
          <div
            v-for="column in level2Columns"
            :key="column.name"
            class="column"
            :class="column.class"
          >
            <slot
              :name="`level2:${column.name}`"
              :item="level2Item"
              :value="rawColumnValue(level2Item, column.name)"
            >
              {{ visibleColumnValue(level2Item, column.name) }}
            </slot>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import Icon from "@/components/ui/Icon";
import {get} from "lodash-es";

export default {
  components: {
    Icon,
  },

  props: {
    /**
     * List columns
     */
    columns: {
      type: Array,
      required: true,
    },

    items: {
      type: Array,
      default: () => {
        return [];
      },
    },

    level1Key: {
      type: String,
      required: true,
    },

    level2Key: {
      type: String,
      required: true,
    },

    childrenKey: {
      type: String,
      required: true,
    },

    initialExpanded: {
      type: Boolean,
      required: false,
      default: false,
    },

    /**
     * Sort data object
     */
    sort: {
      type: Object,
      default: () => ({
        name: "",
        dir: "asc",
      }),
    },
  },

  emits: [
    'sortClick',
  ],

  data() {
    return {
      isExpanded: {},
    };
  },

  computed: {
    allExpanded() {
      return Object.values(this.isExpanded).every(v => v);
    },

    level1Columns() {
      return this.columns.filter(c => c.level === 1);
    },

    level2Columns() {
      return this.columns.filter(c => c.level === 2);
    },
  },

  methods: {
    isComponentField(fieldName) {
      return fieldName instanceof Object;
    },

    sortIcon(columnName) {
      if (this.sort.name === columnName) {
        if (this.sort.dir === 'asc') {
          return 'sortAsc';
        }

        if (this.sort.dir === 'desc') {
          return 'sortDesc';
        }
      }

      return 'sortNone';
    },

    rawColumnValue: get,

    visibleColumnValue(item, column) {
      const v = get(item, column);
      return v == null || v === "" || (Array.isArray(v) && v.length === 0) ? "-" : v;
    },

    hasChildren(item) {
      return item[this.childrenKey]?.length > 0;
    },

    updateExpandedState() {
      const isExpanded = {};

      this.items.forEach(level1Item => {
        const id = level1Item[this.level1Key];
        isExpanded[id] = this.isExpanded[id] ?? this.initialExpanded;
      });

      this.isExpanded = isExpanded;
    },

    toggleAll() {
      const newValue = !this.allExpanded;

      Object.keys(this.isExpanded).forEach(id => {
        this.isExpanded[id] = newValue;
      });
    },

    toggleLevelExpand(level1Item) {
      const id = level1Item[this.level1Key];
      this.isExpanded[id] = !this.isExpanded[id];
    },
  },

  watch: {
    items() {
      this.updateExpandedState();
    },
  },

  created() {
    this.updateExpandedState();
  },
};
</script>

<style scoped>
.tree-list {
  @apply overflow-auto text-black font-normal text-xs w-full;
}

.row {
  @apply flex items-center;
}

.column {
  @apply flex items-center flex-shrink-0;
}

.head {
  @apply sticky top-0 z-10;

  .row {
    @apply bg-white border-b border-black;
  }

  .column {
    @apply font-frank font-bold text-xs px-3 pb-2;
  }
}

.body {
  .row {
    @apply border-b border-graphite-300;

    &:hover {
      @apply bg-active-100;
    }
  }

  .column {
    @apply border-none p-3;
  }
}

.row--level-1 {
  @apply cursor-pointer;
}

.row--level-2 {
  @apply bg-graphite-100;
}

.expand-icon {
  @apply w-3 h-3 mr-2 cursor-pointer;
}
</style>

<template>
    <FinalForm
        ref="form"
        :submit="handleSubmit"
        :initial-values="initialValues"
        class="h-full"
    >
        <template #default="{ handleSubmit, values }">
            <form
                class="form-narrow flex flex-col justify-between min-h-full h-full"
                @submit="handleSubmit"
            >
                <div class="flex flex-grow overflow-y-auto">
                    <div class="form-col w-2/5">
                        <div class="form-row">
                            <TextField
                                name="name"
                                label="amenity name"
                                :validate="composeValidators(required, minLength(2), maxLength(150))"
                                autocomplete="off"
                                :edit-mode="false"
                            />
                        </div>
                        <div class="form-row">
                            <SelectInput
                                name="chargeCodeId"
                                label="charge code"
                                :options="chargeCodesOptions"
                                :edit-mode="editMode"
                            />
                        </div>
                    </div>
                    <div class="form-col flex-grow">
                        <div class="form-row">
                            <TextField
                                name="description"
                                label="description"
                                :validate="composeValidators(required, minLength(5), maxLength(5000))"
                                placeholder="Provide an explanation to accurately understand this amenity"
                                rows="6"
                                multiline
                                :edit-mode="false"
                            />
                        </div>
                    </div>
                </div>
                <TotalPanel
                    :edit-mode="editMode"
                    level="COMMUNITY"
                    :is-group="false"
                    :values="values"
                />
                <modal-footer
                    v-if="editMode"
                    :footer="footer"
                    :tertiary="close"
                />
            </form>
            <loader
                :loading="loading"
                :backdrop="true"
            />
        </template>
    </FinalForm>
</template>

<script>
import { mapGetters } from 'vuex';
import { FinalForm } from 'vue-final-form';
import NotifyMixin from '@/mixins/NotifyMixin';
import ModalNavigation from '@/mixins/ModalNavigation';
import ActionsMixin from '@/mixins/ActionsMixin';
import AmenityMixin from '@/mixins/AmenityMixin';
import Loader from '@/components/ui/Loader';
import ModalFooter from '@/components/ui/modals/ModalFooter';
import ValidatorMixin from '@/components/form/ValidatorMixin';
import InitializeFormMixin from '@/components/form/InitializeFormMixin';
import TextField from '@/components/form/TextField';
import SelectInput from '@/components/form/SelectInput';
import TotalPanel from '@/components/amenities/TotalPanel';

export default {
    components: {
        Loader,
        TotalPanel,
        ModalFooter,
        TextField,
        SelectInput,
        FinalForm,
    },

    mixins: [ModalNavigation, NotifyMixin, ActionsMixin, ValidatorMixin, InitializeFormMixin, AmenityMixin],

    data() {
        return {
            initialValues: {},
            loading: true,
            chargeCodesOptions: [],
        };
    },

    computed: {
        ...mapGetters({
            hasPermission: 'amnt/hasPermission',
        }),

        editMode() {
            return this.hasPermission('CL_AM');
        },

        footer() {
            return {
                primaryButton: 'save',
                tertiaryButton: 'cancel',
            };
        },

        amenityId() {
            return this.$route.params.amenityId;
        },

        formValues() {
            return this.$refs.form.formState.values;
        },
    },

    mounted() {
        this.initializeWatchers();

        Promise.all([this.fetchAmenity(), this.fetchChargeCodes()])
            .then(() => {
                this.$watch(
                    () => this.formValues.discountType,
                    () => this.$refs.form.finalForm.change('discount', 0)
                );
            })
            .catch(error => {
                this.close();
                this.notifyError(error.message);
            })
            .finally(() => {
                this.loading = false;
            });
    },

    methods: {
        async handleSubmit(values) {
            if (this.loading) {
                return;
            }

            const { chargeCodeId, discount, discountType } = values;

            const data = {
                chargeCodeId,
                discount,
                discountType,
            };

            this.loading = true;

            try {
                const { name } = await this.$amntDataProvider.update('communityAmenities', { id: this.amenityId, data });
                this.notifySuccess('Amenity has been updated');
                this.setActiveModalName(name);
            } catch (error) {
                this.notifyError(error.message);
            } finally {
                this.loading = false;
            }
        },

        fetchAmenity() {
            return this.$amntDataProvider
                .getOne('communityAmenities', { id: this.amenityId })
                .then(
                    ({
                        amenity: { name, description, discount: globalDiscount, discountType: globalDiscountType, price: globalPrice },
                        chargeCode,
                        discount,
                        discountType,
                        total,
                        assignments,
                        communityGroups: groups,
                    }) => {
                        this.initialValues = {
                            name,
                            description,
                            chargeCodeId: chargeCode?.id || null,
                            globalDiscount,
                            globalDiscountType,
                            globalPrice,
                            discount,
                            discountType,
                            total,
                            assignments,
                            groups,
                        };
                        this.setActiveModalName(name);
                    }
                );
        },

        fetchChargeCodes() {
            return this.$amntDataProvider.get('communityChargeCodes').then(chargeCodes => {
                this.chargeCodesOptions = chargeCodes.map(({ id, description }) => ({ key: id, value: description }));
            });
        },

        initializeWatchers() {
            this.$watch(
                () => this.formValues.globalPrice,
                val => {
                    if (val < 0) {
                        this.$refs.form.finalForm.change('discount', 0);
                    }
                }
            );

            this.$watch(
                () => [this.formValues.globalPrice, this.formValues.globalDiscount, this.formValues.discount],
                ([globalPrice, globalDiscount, discount]) => {
                    this.$refs.form.finalForm.change(
                        'total',
                        this.getTotal(
                            globalPrice,
                            { discount: globalDiscount, type: this.formValues.globalDiscountType },
                            { discount, type: this.formValues.discountType }
                        )
                    );
                },
                {
                    immediate: true,
                }
            );
        },
    },
};
</script>
